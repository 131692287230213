@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.nav-links {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @include respond(bp700) {
    padding: 0;
  }
}

.nav-links li {
  padding: 0 0.2rem;
}

.nav-link-vanish {
  &,
  & span,
  & button,
  & li {
    @include respond(bp800) {
      padding: 0;
      display: none !important;
    }
  }

  &--highlighted {
    color: $color-primary;
  }
}

a:-webkit-any-link {
  color: inherit;
  text-decoration: none;
}
