@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.widget-zendesk {
  &--no-display {
    display: none !important;
  }
}

@media print {
  .widget-zendesk {
    &,
    & * {
      display: none !important;
    }
  }

  .launcher {
    &,
    & * {
      display: none !important;
    }
  }

  *[name="Janela de mensagens"],
  *[title="Janela de mensagens"] {
    display: none !important;
  }

  *[name="Messaging window"],
  *[title="Messaging window"] {
    display: none !important;
  }

  *[title="Fechar mensagem"] {
    display: none !important;
  }

  *[title="Mensagem da empresa"] {
    display: none !important;
  }

  *[title="Message from company"] {
    display: none !important;
  }

  *[data-testid="unread-indicator-container"] {
    display: none !important;
  }
}
